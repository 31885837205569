var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v(" Changelog ")]),_c('div',{staticClass:"card-header-icon"},[_c('a',{staticClass:"icon",on:{"click":() => { this.$emit('close') }}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])])]),_c('div',{staticClass:"card-content content"},[_c('h2',{staticClass:"has-text-primary subtitle"},[_vm._v(_vm._s(_vm.version))]),_vm._m(0),_c('p',[_vm._v("A new Tier, AAC Cruiserweight Tier, has been added. Please ensure to update your Team to the new Tier to have BIS Table colours display correctly, and best of luck!")]),_vm._m(1),_c('p',[_vm._v("The default values for the Item Level filters in BIS pages have been updated to the new range for Cruiserweight. (740 - 765)")]),_vm._m(2),_vm._m(3),_c('p',[_vm._v("Also fixed some other very well hidden bugs, so thank you to everyone for using the Loot page and giving me good data!")]),_vm._m(4),_c('p',[_vm._v("As mentioned before, I need to update some frontend libraries that the site uses for security and updates sakes.")]),_c('p',[_vm._v("If you have any issues with the site, now is definitely the time to make them known, as I'll be trying to fix a bunch of things while keeping the site looking as much the same as possible!")]),_c('p',[_vm._v("Leave your feedback either in the Discord (link at the bottom of the page) or using the nice lil feedback widget at the bottom right of your page!")]),_vm._m(5),_c('p',[_vm._v("Thank you all for your continued support, SavageAim wouldn't be half the tool it is without y'all!")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" FFXIV Patch 7.2 "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" All the gear added with Patch 7.2 has been added to the site, please enjoy updating your BIS Lists for the new Tier! "),_c('ul',[_c('li',[_vm._v("Ceremonial Weapon, Armour and Accessories - Item Level 740")]),_c('li',[_vm._v("Cruiser Armour and Accessories - Item Level 740")]),_c('li',[_vm._v("????? Trial Weapon - Item Level 745 (name hidden in changelog for spoilers sake)")]),_c('li',[_vm._v("Historia Weapon, Armour and Accessories - Item Level 750")]),_c('li',[_vm._v("Augmented Historia Weapon, Armour and Accessories - Item Level 760")]),_c('li',[_vm._v("Babyface Champion Armour and Accessories - Item Level 760")]),_c('li',[_vm._v("Babyface Champion Weapon - Item Level 765")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Loot Solver Bugfixes "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Fixed a bug where the Loot Solver would assume that you could buy armour pieces from 2nd and 3rd fights of a Tier with the same number of clear-tokens as the tome augmentation items. "),_c('ul',[_c('li',[_vm._v("As a reminder, the Solver assumes you only buy Tome Augmentation items from the 2nd and 3rd fights!")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Work Commencing on SavageAim \"v2\" "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("You can track v2 plans at the GitHub Issue "),_c('a',{attrs:{"href":"https://github.com/SavageAim/app/issues/92","target":"_blank"}},[_vm._v("here!")])])
}]

export { render, staticRenderFns }